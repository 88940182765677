import React from 'react';
import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import SliceZone from '../slices/SliceZone';

const ServicePage = ({location, data}) => {

  const servicePageData = data.prismicServicepage.data;

  return (
    <>
      <SEO 
        title={servicePageData.meta_title} 
        description={servicePageData.meta_description}
        socialImage={servicePageData.social_image.url}
         />
      <Navbar currentPath={location.pathname} />
      <SliceZone 
        slices={servicePageData.body}
      />
      <Footer paddingTop={48} colorTheme="dark" />
    </>
  );
}


export const query = graphql`
  query ServicePageQuery($uid: String) {
    prismicServicepage(uid: {eq: $uid}) {
      data {
        meta_title
        meta_description
        social_image {
          url
        }
        body {
          ... on PrismicServicepageBodyContactusform {
            slice_type
            primary {
              full_width
            }
          }
          ... on PrismicServicepageBodyContactbanner {
            slice_type
          }
          ... on PrismicServicepageBodyPackagedescriptions {
            slice_type
            primary {
              package {
                document {
                  ... on PrismicDealpackage {
                    data {
                      description
                      title
                      sub_title
                      package_color
                      item {
                        package_item {
                          document {
                            ... on PrismicPackageitem {
                              data {
                                description {
                                  html
                                }
                                icon {
                                  alt
                                  url
                                  fluid(maxWidth: 200, maxHeight: 200) {
                                    ...GatsbyPrismicImageFluid
                                  }
                                }
                                title
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicepageBodyOnepackage {
            primary {
              background_color
              info_text {
                html
              }
              large_text
              button_label
              button_link {
                url
              }
              package {
                document {
                  ... on PrismicDealpackage {
                    data {
                      description
                      title
                      sub_title
                      item_background_color
                      item_hover_color
                      package_color
                      item {
                        package_item {
                          document {
                            ... on PrismicPackageitem {
                              data {
                                description {
                                  html
                                }
                                icon {
                                  alt
                                  url
                                }
                                title
                              }
                            }
                          }
                        }
                      }
                      action_button_label
                      action_button_color
                      action_button_link {
                        url
                      }
                      action_button_anchor_tag
                    }
                  }
                }
              }
              package_side
              title
            }
            slice_type
          }
          ... on PrismicServicepageBodyPackages {
            slice_type
            primary {
              background_color
            }
            items {
              package {
                document {
                  ... on PrismicDealpackage {
                    data {
                      description
                      title
                      sub_title
                      item_background_color
                      item_hover_color
                      package_color
                      item {
                        package_item {
                          document {
                            ... on PrismicPackageitem {
                              data {
                                description {
                                  html
                                }
                                icon {
                                  alt
                                  url
                                }
                                title
                              }
                            }
                          }
                        }

                      }
                      action_button_label
                      action_button_color
                      action_button_link {
                        url
                      }
                      action_button_anchor_tag
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicepageBodyLargetitle {
            slice_type
            primary {
              background_color
              title
              underline
              uppercase
              align
            }
          }
          ... on PrismicServicepageBodyEmptybackground {
            slice_type
            primary {
              color
              height
            }
          }
          ... on PrismicServicepageBodyTextwithprimarybutton {
            slice_type
            primary {
              background_color
              button_label
              button_anchor_tag
              button_link {
                url
              }
              info_text {
                html
              }
              large_text
            }
          }
          ... on PrismicServicepageBodyHerotexticon {
            slice_type
            primary {
              background_color 
              image {
                fluid(maxWidth: 553, maxHeight: 553) {
                    ...GatsbyPrismicImageFluid
                  }
              }
              image_max_width
              info_text {
                html
              }
              info_text_double_columns
              large_text
              title
            }
          }
          ... on PrismicServicepageBodyCards {
            slice_type
            primary {
              title
              card_desktop_height
              card_max_width
              card_mobile_height
            }
            items {
              card_text_content {
                html
              }
              card_title
              large_image {
                alt
                url
              }
              large_icon_width
              small_icon {
                alt
                url
              }
              small_icon_width
            }
          }
        }
      }
    }
  }
`

export default ServicePage;